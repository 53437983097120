import React from 'react'
import style from './headline.module.scss'

type Props = {
    children: JSX.Element | string
}

const Headline = (props: Props) => {
    return (
        <div className={style.headline}>
            {props.children}
        </div>
    )
}

export default Headline