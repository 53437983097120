import React from 'react'
import style from './event.module.scss'
import commonStyle from '../Common/common.module.scss'
import Headline from '../Common/Headline/Headline'
import { Event as EventType } from '../../types/Types'
import CheckIcon from '@mui/icons-material/Check';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CloseIcon from '@mui/icons-material/Close';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

type Props = {
    event: EventType
}

const Event = (props: Props) => {

    const icon = () => {
        let ico;
        if (props.event.state === 1) {
            ico = <HourglassTopIcon style={{ width: "40px", height: "100%" }} />
        } else if (props.event.state === 2) {
            ico = <CheckIcon style={{ width: "40px%", height: "100%" }} />
        } else if (props.event.state === 3) {
            ico = <DoDisturbIcon style={{ width: "40px%", height: "100%" }} />
        } else {
            ico = <CloseIcon style={{ width: "40px%", height: "100%" }} />
        }
        return <div>{ico}</div>
    }

    const getColor = () => {
        let color = style.outter;
        if (props.event.state === 1) {
            color = style.stateYellow
        } else if (props.event.state === 2) {
            color = style.stateGreen
        } else if (props.event.state === 3) {
            color = style.stateBlue
        } else {
            color = style.stateRed
        }
        return color
    }
    //console.log(props.event.name + " '" + props.event.judge + "'")
    return (
        <div className={commonStyle.container + " " + style.outter + " " + getColor()}>
            <div className={style.intermediateContainer}>
                <Headline>
                    {props.event.name}
                </Headline>
                <div style={{ "fontSize": "x-large" }}>
                    {props.event.date.trim() !== "" ? props.event.date : "???"}
                </div>
                <div style={{ "fontSize": "medium" }}>
                    {props.event.organizer}
                </div>
                <div className={style.inner}>
                    <div>
                        {props.event.place}
                    </div>
                    <div>
                        {props.event.price}
                    </div>
                </div>
                <div>
                    {props.event.judge.trim() !== "" ? props.event.judge : "???"}
                </div>
            </div>
            {icon()}
        </div>

    )
}

export default Event