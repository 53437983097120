import React, { useEffect, useState } from 'react';

import allReducer from './Reducer/reducerCombiner';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import style from './Components/Common/common.module.scss';
import Headline from './Components/Common/Headline/Headline';
import Event from './Components/Event/Event';
import Spacer from './Components/Common/Spacer';
import eventStyle from './Components/Event/event.module.scss';
import { Event as EventType } from './types/Types';
import { doGetRequest } from './Components/Common/StaticFunctions';
import { CircularProgress, TextField } from '@mui/material';
import appStyle from './app.module.scss'

declare global {
  interface Window {
    globalTS: {
      DOMAIN: string,
      MOBILE_THRESHOLD: number,
      PLZ: string
    };
  }
}

function App() {
  const store = createStore(allReducer, composeWithDevTools())
  const [search, setsearch] = useState("")
  const [events, setevents] = useState<Array<EventType>>([])
  const [isLoaded, setisLoaded] = useState(false)
  useEffect(() => {

    setsearch(decodeURIComponent(window.location.pathname).replace("/", ""))

    setisLoaded(false)
    doGetRequest("events").then(value => {
      if (value.code === 200) {
        const result: Array<EventType> = value.content;
        const sorted = result.sort((first: EventType, second: EventType) => {
          const firstDistance = Math.abs(parseInt(window.globalTS.PLZ) - first.plz);
          const secondDistance = Math.abs(parseInt(window.globalTS.PLZ) - second.plz);
          return firstDistance - secondDistance
        })
        setevents(sorted)
        setisLoaded(true)
      }
    })
  }, [])

  return (
    <Router>
      <div className={style.background}>
        <Provider store={store}>
          <Headline>
            <div className={appStyle.headline}>
              Webmelden Turniere
              <TextField
                variant="standard"
                label="Suche"
                sx={{
                  input: { color: 'white' },
                  label: { color: 'white' },
                }}
                value={search}
                onChange={(value) => {
                  setsearch(value.target.value)
                }}
              />
            </div>
          </Headline>
          <Spacer vertical={15} />
          {!isLoaded ? <div className={appStyle.loader}>
            Lädt Daten von Webmelden
            <CircularProgress />
          </div> : <></>}
          <div className={eventStyle.eventContainer}>
            {events.filter(event => {
              if (search === "") {
                return true
              }
              return event.name.toLowerCase().includes(search.toLowerCase()) ||
                event.judge.toLowerCase().includes(search.toLowerCase()) ||
                event.place.toLowerCase().includes(search.toLowerCase()) ||
                event.organizer.toLowerCase().includes(search.toLowerCase())
            }).map(event => { return <Event event={event} /> })}
          </div>
        </Provider>
      </div>
    </Router>
  );
}

export default App;
